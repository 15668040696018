<template>
  <div class="home">
    <div class="messDetail">
      <HeadTop />
      <span style="margin-left:10px">></span>
      <span style="margin-left:10px">{{title}}</span>
    </div>
    <div class="content">
      <div  class="contLeft">
        <div v-if="!mess||mess==null">
            <a-empty />
        </div>
        <div class="book-textreadguide">
            <!-- <a-spin class="loading" v-show="loading" /> -->
            <div class="book-textreadguide-content">
            <div class="textreadguide-title">{{mess.title}}</div>
            <div class="textreadguide-content">
              {{mess.content}}
            </div>
            </div>
        </div>
      </div>
      <div class="contRight">
        <div class="title">
          <img width="20px" height="20px" src="../../assets/img/53.png" alt="">
          <span class="good_product">精品</span>
        </div>
        <div v-if="recList.length<0">
          <a-empty></a-empty>
        </div>
        <ul class="pro_list">
          <li class="listDetail ell" @click="again(item)" v-for="(item,index) in recList" :key="index">
            {{item.title}}
          </li>
        </ul>
        <div class="title">
          <img width="20px" height="20px" src="../../assets/img/54.png" alt="">
          <span class="good_product">论坛</span>
        </div>
        <div v-if="forumList.length<0">
          <a-empty></a-empty>
        </div>
        <ul class="lun_ul">
          <li class="lun_li" v-for="(item, index) in forumList" :key="index">
            <div class="title ell">{{item.title}}</div>
            <div class="zhuban">{{item.stemFrom}}</div>
            <div class="date">
              时    间：{{format.format(item.startDate)}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import format from '@/utils/format.js'
import axios from 'axios'
export default {
  name: 'bookDetail',
  data(){
    return {
      format,
      mess: {},
      id: '1',
      recList:[],
      forumList:[],
      title: ''
    }
  },
  created () {
    // this.$emit('footer', false);
  },
  mounted(){
    this.id = JSON.parse(sessionStorage.getItem('bookRecommend')).id
    this.title = JSON.parse(sessionStorage.getItem('bookRecommend')).title
    this.getDetail()
    this.recommandList()
  },
  methods: {
    getDetail(){
      let _this =this
      const param = {
        id: _this.id
      }
        axios({
          url: _this.$baseUrl + 'official/bookStore/getClassicsReadDetail',
          method: 'POST',
          data: param
        }).then(res => {
          if(res && res.data &&res.data.resultCode===0){   
            _this.mess = res.data.resultData
            _this.title = _this.mess.title
          }
        })
    },
    recommandList (){ //official/bookStore/getExcellentAndForumList
        let _this = this
        axios({
          url: _this.$baseUrl + 'official/bookStore/getExcellentAndForumList',
          method: 'POST',
          data: {}
        }).then(res => {
          if(res && res.data &&res.data.resultCode===0){   
            _this.recList = res.data.resultData.excellentList
            _this.forumList = res.data.resultData.forumList
          }
        })
    },
    onSearch(){

    },
    handleListChange (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
    },
    again(item){
      this.id = item.id
      this.getDetail()
      this.$forceUpdate()
    }
  },
  components: {
    HeadTop
  }
}
</script>
<style scoped lang="less">
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}
  .home{
    .messDetail{
      display: flex;
      line-height:60px;
      width: 1140px;
      margin: 0 auto;
    }
    .readImg{
      width:100%;
      height:260px;
    }
    .content{
      width: 1140px;
      // height: 400px;
      margin: 0 auto;
      .contLeft{
        min-height: 650px;
        width: 820px;
        float: left;
        padding: 30px 50px;
        background: #fff;
        margin-bottom: 30px;
        .book-textreadguide {
            background-color: #f1f0f0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .book-textreadguide-content {
        border-radius: 4px;
        background-color: #fff;
        font-weight: normal;
        width: 800px;
        justify-content: center;
        flex-direction: column;
        .textreadguide-title {
            font-size: 18px;
            text-align: center;
            font-weight: bold;
            color: #000000;
        }
        .textreadguide-content {
            // font-family: "STSongti-SC-Black";
            font-size: 16px;
            white-space: pre-wrap;
            line-height:2em;
            color: #000000;
        }
        }
      }
      //right
      .contRight{
        background: #fff;
        min-height: 520px;
        float: left;
        margin-left: 20px;
        width: 300px;
        padding: 20px;
        .title{
          height: 40px;
          line-height: 40px;
          .good_product{
            font-size: 16px;
            color: #DB5647;
            font-weight: 500;
            margin-left: 10px;
          }
        }
        .pro_list{
          margin-top: 10px;
          .listDetail{
            cursor: pointer;
            border-bottom: 1px solid #EEE9DE;
            color: #000000;
            line-height: 56px;
            height: 56px;
            width: 250px;
          }
        }
        .lun_ul{
          .lun_li{
            border-bottom: 1px solid #EEE9DE;;
            .title{
              color: #000000;
              line-height: 30px;
              height: 30px;
              font-size: 14px;
              width: 250px;
            }
            .zhuban{
              color: #7F7F7F;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
            }
            .date{
              color: #7F7F7F;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .spanActive{
    width: 80px;
    height: 32px;
    line-height: 32px;
    background: #FBEEEC;
    border-radius: 4px;
    color: #DB5647;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  //gonggomg
  .ell{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>